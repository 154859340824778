import React from 'react';

import TopBar from '../components/TopBar';
import Header from '../components/Header';
import Base from '../layout/base';
import { getIcon } from '../components/StatusIcons';
import BaseTable from '../components/Table/BaseTable';
import Status from '../components/Content/Status';
import H2 from '../components/Content/H2';

const projekte = [
    {
        objektTyp: "Wohnung",
        strasse: "Dorfstrasse 42",
        standort: "Luzern",
        plz: "6005",
        status: 0,
    },
    {
        objektTyp: "Haus",
        strasse: "Anna-Neumanngasse 8",
        standort: "Luzern",
        plz: "6003",
        status: 1,
    },
    {
        objektTyp: "Wohnung",
        strasse: "Sterngasse 10",
        standort: "Kriens",
        plz: "6010",
        status: 2,
    },
    {
        objektTyp: "Wohnung",
        strasse: "Hirschmattstrasse 28",
        standort: "Luzern",
        plz: "6003",
        status: 3,
    }
]

const failed_projekte = [
    {
        objektTyp: "Wohnung",
        strasse: "Bruchstrasse 32",
        standort: "Luzern",
        plz: "6003",
        status: 1,
    },
]

const headers = [
    {
        label: "Objekttyp",
        className: "py-1.5 pl-4 pr-3 text-left text-md font-normal text-gray-900 sm:pl-3",
    }, {
        label: "Strasse",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
    {
        label: "PLZ",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
    {
        label: "Ort",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
    {
        label: "Status",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
]

function Projekte() {

    const projekteRows = projekte.map((p, index) => {
        return {
            cols: [
                {
                    className: "whitespace-nowrap py-4 pl-4 pr-3 text-md font-bold text-gray-900 sm:pl-3 w-[20%]",
                    children: (
                        <span>
                            {p.objektTyp}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500 w-[35%]",
                    children: (
                        <span>
                            {p.strasse}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[15%]",
                    children: (
                        <span>
                            {p.plz}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[25%]",
                    children: (
                        <span>
                            {p.standort}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500 w-[25%]",
                    children: (<Status status={p.status} />)
                }
            ]
        }
    });


    const projekteRows2 = failed_projekte.map((p, index) => {
        return {
            cols: [
                {
                    className: "whitespace-nowrap py-4 pl-4 pr-3 text-md font-bold text-gray-900 sm:pl-3 w-[20%]",
                    children: (
                        <span>
                            {p.objektTyp}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500 w-[35%]",
                    children: (
                        <span>
                            {p.strasse}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[15%]",
                    children: (
                        <span>
                            {p.plz}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[25%]",
                    children: (
                        <span>
                            {p.standort}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500 w-[25%]",
                    children: (<Status status={p.status} />)
                }
            ]
        }
    });


    return (
        <Base>
            <div className="App">
                <TopBar />
                <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden text-left">
                    <div className="relative z-10 mb-8 md:mb-2 md:px-6"></div>
                    <Header title="Meine Projekte" />

                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <BaseTable
                                    data={projekteRows}
                                    headers={headers}
                                    filter={true}
                                />
                            </div>
                        </div>
                    </div>


                    <div className='mt-24 opacity-50 pointer-events-none'>
                        <H2>
                            Abgebrochene Projekte
                        </H2>
                        <div className="mt-8 flex flex-col">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <BaseTable
                                        data={projekteRows2}
                                        headers={headers}
                                        filter={true}
                                    />
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Base >
    );
}

export default Projekte;
